<template>
	<div>
		
	</div>
</template>

<script>
	export default{
		data(){
			return{
			
			}
		},
		created() {
		    // 定时触发滚动函数
		    setInterval(this.messagescroll,2000)
		},
		methods:{
			
		},
	}
</script>

<style lang="scss">
	

</style>
