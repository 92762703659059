<template>
  <div class="page-box">
        <div class="mian-top-img">
			<!-- <img src="@/assets/login-close.png" class="wx-close-img gestures" @click="closeWin"/> -->
		</div>
		<div class="mians-tel">微信登录</div>
		<div class="mian-items">
			<div class="quick-card-item" v-show="isShow">
				<wxlogin
				    :appid="appid"
				    :scope="snsapi_login" 
				    :theme="'black'"
				    :redirect_uri="encodeURIComponent(redirectUrl)"
				    :href="'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKCSBkaXNwbGF5OiBub25lOwoJfQoJLmltcG93ZXJCb3ggLnN0YXR1cy5zdGF0dXNfYnJvd3NlciB7CgkgZGlzcGxheTogbm9uZTsKCX0KCS5pbXBvd2VyQm94IC5xcmNvZGUgewoJIGJvcmRlcjogbm9uZTsKCSB3aWR0aDogMjQ0cHg7CgkgaGVpZ2h0OiAyNDRweDsKCX0KCS5pbXBvd2VyQm94IC5zdGF0dXN7CgkgZGlzcGxheTogbm9uZQoJfQoJLmltcG93ZXJCb3ggLmluZm97CgkgZGlzcGxheTogbm9uZQoJfQ=='"
				    rel="external nofollow"
				>
				 </wxlogin>
			</div>
		</div>
		<div class="wx-tips-text">请使用微信扫描二维码登录</div>
  </div>
</template>

<script>
	import wxlogin from 'vue-wxlogin'
	import qs from 'qs'
	export default {
		components: { wxlogin },
		data(){
			return {
				appid:'wx27a567fb1ea3816f',
				snsapi_login:'',
				redirectUrl:'https://api.360qnw.com',
				isShow:false
			}
		},

		activated() {
			// console.log('23434456456')
			// this.isShow = false
		},
		created() {
			// this.isShow = false
			let prm = {
				soft_id:'gamemodifier_win',
				curr_ver:'1.0.0.1',
				device_id:'1234',
				mac_code:'1234',
				os:1,
				source: window.win_source(),
				device_code: window.win_device_id()
			}
			this.$http({
			  url: "https://api.360qnw.com/api/open-wx-login?"+qs.stringify(prm),
			  method: "get",
			  header: {
			      'content-type': 'application/json', 
			  },
			}).then(res=>{
				if(res.data.code==0){
					let urlInfo = qs.parse(res.data.data.url.split('?')[1])
					this.appid = urlInfo.appid
					this.snsapi_login = urlInfo.scope
					this.redirectUrl = urlInfo.redirect_uri
					this.isShow = true
					console.log(urlInfo)
				}
			})
		},
		methods:{
			closeWin(){
				window.win_hide_login()
			},
		}
	}
</script>
<style lang='scss'>
	.page-box{
	
	background-color: #262C34;
	
	height: 100vh;
	
	}
	.mian-top-img{
		display: flex;
		justify-content: flex-end;
		padding-top: 4px;
	}
	.wx-close-img{
		width: 48px;
		height: 28px;
	}
	.mians-tel{
		text-align: center;
		color: #FFFFFF;
		font-size: 20px;
		font-weight: bold;
		/* padding-top: ; */
	}
	.mian-items{
		padding-top: 10px;
		display: flex;
		justify-content: center;
	}
	.quick-card-item{
		height: 244px;
	}
	.wx-tips-text{
		text-align: center;
		padding-top: 10px;
		color: #FFFFFF;
		font-size: 14px;
		margin-top: 20px;
	}
</style>
